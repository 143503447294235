<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'AutoawardList',
              }"
              >自動派獎設定</b-breadcrumb-item
            >
            <b-breadcrumb-item active>自動派獎列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">自動派獎列表</h4>
        <div
          class="
            col-12 col-xl-6
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-form-select
            v-model="merchantId"
            :options="merchants"
            @change="fetchAutoawards"
          ></b-form-select>

          <!-- <b-button
            v-if="
              checkPermission([consts.POYA_AUTOAWARD_CREATE]) && !!this.merchantId
            "
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="primary"
            @click="handleCreate"
          >
            <i class="fa fa-plus"></i>新增派獎
          </b-button> -->
          <b-button
            v-if="
              checkPermission([consts.POYA_AUTOAWARD_CREATE]) && !!this.merchantId
            "
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="primary"
            @click="handleCreateV3"
          >
            <i class="fa fa-plus"></i>新增派獎
          </b-button>
        </div>
      </div>

      <b-table
        striped
        hover
        responsive
        :items="autoawards"
        :fields="fields"
        :busy="isLoading"
        no-local-sorting
        @sort-changed="sortingChanged"
        :sort-by.sync="orderBy"
        :sort-desc.sync="orderDesc"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>

        <template #cell(actions)="data">
          <!-- <b-button
            v-if="checkPermission([consts.POYA_AUTOAWARD_VIEW])"
            variant="inverse-primary"
            :to="{
              name: 'AutoawardView',
              params: {
                autoawardId: data.item.id,
              },
            }"
            >查看</b-button
          > -->
          <b-button
            v-if="checkPermission([consts.POYA_AUTOAWARD_VIEW])"
            variant="inverse-primary"
            :disabled="data.item.config.version != 3"
            :to="{
              name: 'AutoawardViewV3',
              params: {
                autoawardId: data.item.id,
              },
            }"
            >查看</b-button
          >
          <!-- <b-button
            v-if="checkPermission([consts.POYA_AUTOAWARD_MODIFY])"
            class="ml-2"
            variant="inverse-warning"
            :to="{
              name: 'AutoawardEdit',
              params: {
                autoawardId: data.item.id,
              },
            }"
            :disabled="isAfter(new Date(), new Date(data.item.end_at))"
            >編輯</b-button
          > -->
          <b-button
            v-if="checkPermission([consts.POYA_AUTOAWARD_MODIFY])"
            class="ml-2"
            variant="inverse-warning"
            :to="{
              name: 'AutoawardEditV3',
              params: {
                autoawardId: data.item.id,
              },
            }"
            :disabled="isAfter(new Date(), new Date(data.item.end_at)) || data.item.config.version != 3 "
            >編輯</b-button
          >

          <b-button
            v-if="checkPermission([consts.POYA_AUTOAWARD_MODIFY])"
            class="ml-2"
            variant="danger"
            @click="handleDelete(data.item.id, data.item.title)"
            :disabled="isAfter(new Date(), new Date(data.item.start_at))"
            >刪除</b-button
          >
        </template>
      </b-table>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination
          class="separated"
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="autoawardList"
          @change="(page) => fetchAutoawards(page)"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import autoawardApi from "../../../apis/autoaward";
import merchantApi from "../../../apis/merchant";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import moment from "moment";
import isAfter from "date-fns/isAfter";

export default {
  data() {
    return {
      consts,
      isLoading: false,
      search: "",
      autoawards: [],
      merchantId: null,
      merchants: [{ value: null, text: "請選擇" }],
      fields: [
        {
          key: "title",
          label: "標題",
        },
        {
          key: "start_at",
          label: "起始日",
          formatter: (value) => {
            return moment(value).isValid()
              ? moment(value).format("YYYY-MM-DD")
              : undefined
          },
          sortable: true,
          sortKey: "start_at"
        },
        {
          key: "end_at",
          label: "結束日",
          formatter: (value) => {
            return moment(value).isValid()
              ? moment(value).format("YYYY-MM-DD")
              : undefined
          },
          sortable: true,
          sortKey: "end_at"
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
      perPage: 15,
      currentPage: 1,
      total: 0,
      orderBy: '',
      orderDesc: null
    };
  },
  computed: {
    orgId() {
      return this.$route.params.orgId;
    },
  },
  async mounted() {
    if (!this.checkPermission([consts.POYA_AUTOAWARD_VIEW])) {
      this.$swal
        .fire({
          type: "error",
          text: "你沒有權限訪問此頁面",
          confirmButtonColor: "#d33",
        })
        .then(() => {
          this.$router.push({ name: "OrganizationList" });
        });
    } else {
      await this.fetchMerchants();
      await this.fetchAutoawards();
    }
  },
  methods: {
    isAfter,
    async fetchAutoawards(page = 1, orderBy = '', orderDesc = true) {
      try {
        this.isLoading = true;
        const { data } = await autoawardApi.getAutoawards(
          this.merchantId, { page, order_by: orderBy, order_direction: orderDesc ? 'desc' : 'asc'  }
        );
        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
        this.autoawards = data.data;
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },
    async sortingChanged(ctx) {
      try {
        this.isLoading = true;
        await this.fetchAutoawards(1, ctx.sortBy, ctx.sortDesc);
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },
    async handleDelete(autoawardId, title) {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>自動派獎標題：${title}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              this.isLoading = true;
              await autoawardApi.deleteAutoaward(autoawardId);
              await this.fetchAutoawards();
            } catch (error) {
              console.log("");
            }
            this.isLoading = false;
          }
        });
    },
    handleCreate() {
      this.$router.push({
        name: "AutoawardCreate",
        query: { merchantId: this.merchantId },
      });
    },
    handleCreateV3() {
      this.$router.push({
        name: "AutoawardCreateV3",
        query: { merchantId: this.merchantId },
      });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async fetchMerchants() {
      const { data } = await merchantApi.list();
      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];

      data.data.forEach(merchant => {
        if (merchant.type == 'LINE') {
          this.merchantId = merchant.id
        }
      }) 

    },
  },
};
</script>
